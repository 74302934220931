import React from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../../layouts/Landing';
import ProductsBrowser from '../../routes/ProductsBrowser';

interface TasteItPageProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                tagline: string;
            };
        };
    };
}

const tasteIt = ({
    data: {
        location,
        site: {
            siteMetadata: { name },
        },
    },
}: TasteItPageProps) => {
    return (
        <LandingLayout lang="it" location={location} isFluid={false}>
            <ProductsBrowser lang="it" type="taste" />
        </LandingLayout>
    );
};

export const tasteItPageQuery = graphql`
    query tasteItPageQuery {
        site {
            siteMetadata {
                name
                tagline
            }
        }
    }
`;

export default tasteIt;
